import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
	static targets = [
		'form',
		'checkbox',
		'mainCheckbox',
		'feedback',
		'exportCount',
	];
	static values = { url: String, feedback: String };

	connect() {
		if (this.hasFeedbackTarget) {
			this.feedbackTarget.innerText = this.feedbackValue;
		}
	}

	async toggleCheck(evt) {
		evt.preventDefault();
		evt.stopPropagation();

		const marked = evt.target.checked;
		const id = evt.target.value;

		const options = {
			method: 'PATCH',
			headers: {
				'Content-Type': 'application/json',
				'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')
					.content,
			},
			body: JSON.stringify({ marked, id }),
		};

		const response = await fetch(this.urlValue, options);
		const data = await response.json();

		if (this.hasFeedbackTarget) {
			this.feedbackTarget.innerText = data.count;
		}

		if (this.hasExportCountTarget) {
			this.exportCountTarget.innerText = data.count;
		}
	}

	async checkAll(evt) {
		evt.preventDefault();
		evt.stopPropagation();

		let marked = null;

		if (!evt.target.checked) {
			this.checkboxTargets.forEach((checkbox) => {
				checkbox.checked = false;
			});

			marked = 'clear';
		} else {
			this.checkboxTargets.forEach((checkbox) => {
				checkbox.checked = true;
			});

			marked = 'mark';
		}

		const options = {
			method: 'PATCH',
			headers: {
				'Content-Type': 'application/json',
				'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')
					.content,
			},
			body: JSON.stringify({
				marked: marked,
				ids: this.checkboxTargets.map((checkbox) => checkbox.value),
			}),
		};

		const response = await fetch(this.urlValue, options);
		const data = await response.json();

		if (this.hasFeedbackTarget) {
			this.feedbackTarget.innerText = data.count;
		}

		if (this.hasExportCountTarget) {
			this.exportCountTarget.innerText = data.count;
		}
	}

	async clearAll(evt) {
		evt.preventDefault();
		evt.stopPropagation();

		this.checkboxTargets.forEach((checkbox) => {
			checkbox.checked = false;
		});

		this.mainCheckboxTarget.checked = false;

		const options = {
			method: 'PATCH',
			headers: {
				'Content-Type': 'application/json',
				'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')
					.content,
			},
			body: JSON.stringify({
				marked: 'none',
				ids: this.checkboxTargets.map((checkbox) => checkbox.value),
			}),
		};

		const response = await fetch(this.urlValue, options);
		const data = await response.json();

		if (this.hasFeedbackTarget) {
			this.feedbackTarget.innerText = data.count;
		}

		this.exportCountTarget.innerText = data.count;
	}
}
