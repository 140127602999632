import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
	static targets = ['pagination', 'list', 'link'];
	static values = { baseUrl: String };

	connect() {
		this.setLinks();
		this.form = document.querySelector('#resources-search-form');
		this.form.addEventListener('change', this._queryString.bind(this));
	}

	setLinks() {
		this.linkTargets.forEach(this._bindLink.bind(this));
	}

	_bindLink(link) {
		link.addEventListener('click', this._nextPage.bind(this));
	}

	_queryString() {
		return new URLSearchParams(new FormData(this.form)).toString();
	}

	async _nextPage(evt) {
		evt.preventDefault();
		evt.stopPropagation();

		const options = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')
					.content,
			},
		};

		// prettier-ignore
		const url = `${this.baseUrlValue}?page=${evt.target.innerText}&${this._queryString()}`;
		const response = await fetch(url, options);
		const data = await response.json();

		this.listTarget.innerHTML = data.list;
		this.paginationTarget.outerHTML = data.pagination;
		this.setLinks();
	}
}
