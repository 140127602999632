import { Controller } from '@hotwired/stimulus';
import { useClickOutside } from 'stimulus-use';

export default class extends Controller {
	static targets = [
		'modal',
		'overlay',
		'details',
		'title',
		'id',
		'address',
		'sector',
		'conditions',
	];

	connect() {
		useClickOutside(this, { element: this.detailsTarget });

		document.addEventListener(
			'mission:ranked',
			this._updateMissionDetails.bind(this)
		);
	}

	open(evt) {
		evt.preventDefault();
		evt.stopPropagation();

		this.modalTarget.classList.remove('hidden');
		this.overlayTarget.classList.remove('hidden');
	}

	close(evt) {
		evt.preventDefault();
		evt.stopPropagation();

		this.modalTarget.classList.add('hidden');
		this.overlayTarget.classList.add('hidden');
		this._unlockScreen();
	}

	_updateMissionDetails({
		detail: {
			missionId,
			missionTitle,
			missionAddress,
			missionSector,
			missionConditions,
		},
	}) {
		this.titleTarget.innerText = missionTitle;
		this.idTarget.innerText = missionId;
		this.addressTarget.innerText = missionAddress;
		this.sectorTarget.innerText = missionSector;
		this.conditionsTarget.innerText = missionConditions;
	}

	clickOutside(evt) {
		this.modalTarget.classList.add('hidden');
		this._unlockScreen();
	}

	_lockScreen() {
		document.body.style.height = '100vh';
		document.body.style.overflow = 'hidden';
	}

	_unlockScreen() {
		document.body.style.height = 'unset';
		document.body.style.overflow = 'scroll';
	}
}
