import SlimSelect from 'slim-select';
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { placeholder: String };

  connect() {
    this.slimSelect = new SlimSelect({
      select: this.element,
      settings: {
        maxValuesShown: 100,
        placeholderText: this.placeholderValue || 'Select an option',
      },
    });
  }
}
