import { Controller } from '@hotwired/stimulus';
import { useUtilities } from './mixins/utilities';
import { useRequests } from './mixins/requests';

export default class extends Controller {
  static targets = [ "modal", "form", "modalContent" ]

  connect() {
    useUtilities(this);
    useRequests(this);
  }

  async submit(evt) {
    evt.preventDefault();
    
    const options = {
      method: 'POST',
      body: new FormData(evt.target)
    }

    const data = await this.fetch(evt.target.action, options);
    this.formTarget.outerHTML = data.html;
  }

  async verify(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    const options = {
      method: 'POST',
      body: new FormData(evt.target)
    }

    const data = await this.fetch(evt.target.action, options);
    
    if (data.success) {
      this.modalContentTarget.outerHTML = data.html;
      this.verified = true;
    } else {
      this.formTarget.outerHTML = data.html;
    }
  }

  async reset(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    const data = await this.fetch(evt.target.href);
    this.formTarget.outerHTML = data.html;
  }

  toggle(evt) {
    if (this.verified) {
      window.location.reload();
      return; 
    }

    evt.preventDefault();
    console.log(this.modalTarget)

    this.toggleAll(
      this.modalTarget, 
      [ 'opacity-0', 
        'opacity-100', 
        'pointer-events-none', 
        'pointer-events-auto', 
        '-z-1', 
        'z-20'
      ]
    );
  }
}
