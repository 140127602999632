import { Controller } from '@hotwired/stimulus';
import { Sortable } from 'sortablejs';
import { useRequests } from '../mixins/requests';

export default class extends Controller {
	static targets = ['checkbox'];
	static values = { url: String };

	connect() {
		useRequests(this);

		this.sortable = Sortable.create(this.element, {
			onEnd: this._sort.bind(this),
		});
	}

	async _sort(evt) {
		const columns = this.checkboxTargets.map((el) => el.value);

		const options = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')
					.content,
			},
			body: JSON.stringify({ columns }),
		};

		const response = await fetch(this.urlValue, options);
		const data = await response.json();
	}
}
