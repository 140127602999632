import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
	static targets = ['form', 'section'];

	toggle(evt) {
		evt.preventDefault();

		this.sectionTarget.classList.toggle('hidden');
		this.formTarget.classList.toggle('hidden');
	}
}
