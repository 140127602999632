import ScrollReveal from 'scrollreveal';
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
	static targets = ['single', 'multiple'];

	initialize() {
		this.sr = ScrollReveal();
		this.initSingles();
		this.initMultiples();
	}

	initSingles() {
		this.singleTargets.forEach((item) => {
			this.sr.reveal(item, this.options(item));
		});
	}

	initMultiples() {
		this.multipleTargets.forEach((item) => {
			this.sr.reveal(item.children, this.options(item));
		});
	}

	options(item) {
		return {
			mobile: false,
			distance: '1%',
			delay: parseInt(item.dataset.delay) || 0,
			origin: item.dataset.origin || 'bottom',
			opacity: 0,
			viewFactor: parseFloat(item.dataset.viewFactor) || 0,
			duration: parseInt(item.dataset.duration) || 800,
			interval: 200,
		};
	}
}
