import { Controller } from '@hotwired/stimulus';
import { useRequests } from '../mixins/requests';

export default class extends Controller {
  static targets = [
    'main',
    'list',
    'users',
    'usersInput',
    'missions',
    'missionsInput',
    'form',
    'search_form',
    'groups_list',
  ];

  connect() {
    useRequests(this);
  }

  async create(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    const options = {
      method: this.formTarget.method.toUpperCase(),
      body: new FormData(this.formTarget),
    };

    const url = this.formTarget.action;
    const data = await this.fetch(url, options);
    this.formTarget.outerHTML = data.html;

    if (data.list) {
      this.listTarget.innerHTML = data.list;
    }

    if (data.toast) {
      const event = new CustomEvent('toast', {
        detail: { value: data.toast },
      });

      window.dispatchEvent(event);
    }
  }

  async form(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    let url;

    if (evt.params.id == 'new') {
      url = '/admin/groups/new';
    } else {
      url = `/admin/groups/${evt.params.id}/edit`;
    }

    const data = await this.fetch(url);
    this.mainTarget.innerHTML = data.html;
  }

  async destroy(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    const url = evt.target.href;
    const data = await this.fetch(url, { method: 'DELETE' });
    this.mainTarget.innerHTML = data.html;

    if (data.list) {
      this.listTarget.innerHTML = data.list;
    }

    if (data.toast) {
      const event = new CustomEvent('toast', {
        detail: { value: data.toast },
      });

      window.dispatchEvent(event);
    }
  }

  async search(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    const fullUrl = `${this.search_formTarget.action}?query=${evt.target.value}`;
    const data = await this.fetch(fullUrl);
    this.groups_listTarget.innerHTML = data.html;
  }

  async copy_resources(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    const data = await this.fetch(
      `/admin/groups_copy_resources?resource=${evt.params.resource}`
    );

    const input = (() => {
      switch (evt.params.resource) {
        case 'user':
          return this.usersInputTarget;
        case 'mission':
          return this.missionsInputTarget;
        default:
          return null;
      }
    })();

    const controller = this.application.getControllerForElementAndIdentifier(
      input,
      'slim-select'
    );

    if (controller) {
      controller.slimSelect.setSelected(data.users, false);
    } else {
      console.log('Controller not found');
    }
  }

  toggle_fields(evt) {
    this.element.querySelectorAll('.groupables').forEach((groupable) => {
      groupable.classList.toggle('hidden');
    });

    this.element
      .querySelector(`[data-admin--groups-target="${evt.target.value}"]`)
      .classList.remove('hidden');
  }
}
