import { Controller } from '@hotwired/stimulus';
import { Sortable } from 'sortablejs';
import { useRequests } from './mixins/requests';

export default class extends Controller {
	static targets = ['position'];
	static values = { active: Boolean, selectedMissionId: Number };

	connect() {
		useRequests(this);

		this.sortable = Sortable.create(this.element, {
			onEnd: this.rank.bind(this),
			sort: this.activeValue,
		});
	}

	async rank(evt) {
		const id = evt.item.dataset.id;
		const form = new FormData();

		form.append('position', evt.newIndex);

		this.fetch(`/participations/${id}/rank`, {
			method: 'PATCH',
			body: form,
		});

		this.positionTargets.forEach((target, index) => {
			target.innerText = index + 1;
		});

		this._updateSelectedMissionId(id);
		const dataset = this.element.firstElementChild.dataset;

		const eventData = {
			missionId: dataset.missionId,
			missionTitle: dataset.missionTitle,
			missionAddress: dataset.missionAddress,
			missionSector: dataset.missionSector,
			missionConditions: dataset.missionConditions,
		};

		const event = new CustomEvent('mission:ranked', { detail: eventData });
		document.dispatchEvent(event);
	}

	_updateSelectedMissionId(id) {
		this.selectedMissionIdValue = id;
	}
}
