import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
	static targets = ['input', 'submit', 'fileName', 'feedback', 'warning'];
	static values = { feedback: String };

	inputChanged(evt) {
		const fileName = evt.target.files[0].name;
		const validCsvFile = fileName.split('.').pop() === 'csv';

		this.inputTarget.files = validCsvFile ? evt.target.files : null;

		this.fileNameTarget.innerText = fileName;
		this._feedback(validCsvFile);
	}

	_feedback(validCsvFile) {
		if (validCsvFile) {
			this.fileNameTarget.classList.remove('text-red-500');
			this.fileNameTarget.classList.add('text-green-500');
			this.feedbackTarget.innerText = '';

			this.submitTarget.classList.add('opacity-100');
			this.submitTarget.disabled = false;
		} else {
			this.fileNameTarget.classList.remove('text-green-500');
			this.fileNameTarget.classList.add('text-red-500');
			this.feedbackTarget.innerText = this.feedbackValue;

			this.submitTarget.classList.add('opacity-50');
			this.submitTarget.disabled = true;
		}
	}
}
