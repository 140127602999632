import { Controller } from '@hotwired/stimulus';
import Toastify from 'toastify-js';
import 'toastify-js/src/toastify.css';

export default class extends Controller {
	connect() {
		window.addEventListener('toast', this.toast.bind(this));
	}

	toast(evt) {
		this.toast = Toastify({
			text: evt.detail.value,
			duration: 3000,
			newWindow: true,
			close: true,
			gravity: 'bottom', // `top` or `bottom`
			position: 'right', // `left`, `center` or `right`
			stopOnFocus: true, // Prevents dismissing of toast on hover
			style: {
				background: 'linear-gradient(to right, #2590EB, #60A5FA)',
			},
		}).showToast();
	}
}
