import { Controller } from '@hotwired/stimulus';
import { useRequests } from '../mixins/requests';

export default class extends Controller {
	static targets = ['form', 'list'];
	static values = {
		missionId: Number,
	};

	connect() {
		useRequests(this);
	}

	async input(evt) {
		evt.preventDefault();
		evt.stopPropagation();

		const fullUrl = `${this.formTarget.action}?query=${evt.target.value}&mission_id=${this.missionIdValue}`;
		const data = await this.fetch(fullUrl);
		this.listTarget.outerHTML = data.html;
	}
}
