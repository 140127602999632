import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
	static targets = ['collapse'];
	static values = { class: String };

	toggleCollapse(evt) {
		evt.preventDefault();
		evt.stopPropagation();

		this.collapseTarget.classList.toggle('max-h-0');
		this.collapseTarget.classList.toggle(this.classValue);

		evt.currentTarget.querySelector('img').classList.toggle('rotate-180');
	}
}
