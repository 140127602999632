import { useRequests } from './mixins/requests';
import { useUtilities } from './mixins/utilities';
import { Controller } from 'stimulus';

export default class extends Controller {
	// static targets = ['target'];
	// static classes = ['class'];
	static values = { add: String, remove: String, classes: Array };

	initialize() {}

	connect() {
		useRequests(this);
		useUtilities(this);
	}

	async toggle(evt) {
		evt.preventDefault();
		evt.stopPropagation();

		const data = await this.fetch(evt.currentTarget.href, { method: 'POST' });
		const cardBtn = document.querySelector(
			`#favorite-mission-${data.mission_id}`
		);

		console.log(data.mission_id);

		if (data.liked) {
			this.element.innerText = this.removeValue;

			if (this.element !== cardBtn) {
				cardBtn.innerText = this.removeValue;
			}
		} else {
			this.element.innerText = this.addValue;

			if (this.element !== cardBtn) {
				cardBtn.innerText = this.addValue;
			}
		}

		this.toggleAll(this.element, this.classesValue);

		if (this.element !== cardBtn) {
			this.toggleAll(cardBtn, this.classesValue);
		}
	}
}
