import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
	static targets = ['main', 'nav', 'scrollable'];
	static values = { fetchSectionUrl: String };

	async fetchSection(evt) {
		evt.preventDefault();
		evt.stopPropagation();

		const fullUrl = `${this.fetchSectionUrlValue}?section=${evt.target.dataset.section}`;
		const options = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')
					.content,
			},
		};

		const response = await fetch(fullUrl);
		const data = await response.json();
		this.mainTarget.innerHTML = data.html;
		this.scrollableTarget.scrollTo(0, 0);
		this._setNav(evt.target);
		this._dispatch('section:loaded');
	}

	_setNav(item) {
		this.navTargets.forEach((navItem) => {
			navItem.classList.remove('bg-blue-200');
		});
		item.classList.add('bg-blue-200');
	}

	_dispatch(eventName) {
		const event = new CustomEvent(eventName, {
			bubbles: true,
			cancelable: true,
		});

		document.dispatchEvent(event);
	}
}
