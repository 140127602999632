import { Controller } from '@hotwired/stimulus';
import { useClickOutside } from 'stimulus-use';
import { useRequests } from '../mixins/requests';

export default class extends Controller {
	static targets = ['modal', 'details', 'overlay'];
	static values = { id: Number };

	connect() {
		useRequests(this);
		useClickOutside(this, { element: this.detailsTarget });
	}

	open(evt) {
		evt.preventDefault();
		evt.stopPropagation();

		this.idValue = evt.currentTarget.dataset.id;
		this._fetchData();
		this.detailsTarget.innerHTML = this._loader();

		this.modalTarget.classList.remove('hidden');
		this.overlayTarget.classList.remove('hidden');

		this._lockScreen();
	}

	close(evt) {
		evt.preventDefault();
		evt.stopPropagation();

		this.modalTarget.classList.add('hidden');
		this.overlayTarget.classList.add('hidden');
		this._unlockScreen();
	}

	clickOutside(evt) {
		this.modalTarget.classList.add('hidden');
		this._unlockScreen();
	}

	async _fetchData() {
		const data = await this.fetch(`/missions/${this.idValue}/details`);
		this._details(data);
	}

	_details(data) {
		this.detailsTarget.innerHTML = data.html;
	}

	_lockScreen() {
		document.body.style.height = '100vh';
		document.body.style.overflow = 'hidden';
	}

	_unlockScreen() {
		document.body.style.height = 'unset';
		document.body.style.overflow = 'scroll';
	}

	_loader() {
		return `
      <div class="h-full flex justify-center items-center">
        <div class="animate-spin rounded-full h-32 w-32 border-b-2 border-blue-500"></div>
      </div>
    `;
	}
}
