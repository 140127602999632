import { Controller } from '@hotwired/stimulus';
import { useUtilities } from './mixins/utilities';
import { useRequests } from './mixins/requests';
export default class extends Controller {
	static targets = ['form', 'infos', 'weeklyHoursInput', 'weeklyHoursDropdown'];
	static values = { weeklyHoursOpen: Boolean };

	connect() {
		useUtilities(this);
		useRequests(this);

		if (this.weeklyHoursOpenValue) this.dropdownToInput('open');
	}

	display(evt) {
		evt.preventDefault();
		event.stopPropagation();

		this.removeAll(this.formTarget, ['hidden', 'block']);
		this.addAll(this.infosTarget, ['hidden', 'block']);
	}

	async submit(evt) {
		evt.preventDefault();
		evt.stopPropagation();

		const data = await this.fetch(this.formTarget.action, {
			method: 'POST',
			body: new FormData(this.formTarget),
		});

		this.formTarget.outerHTML = data.html;
	}

	dropdownToInput(status = 'closed') {
		const wrapper = this.weeklyHoursInputTarget;
		const dropdown = this.weeklyHoursDropdownTarget;
		const input = wrapper.querySelector('input[type="text"]');

		if (typeof status === 'string' && status === 'open') {
			dropdown.selectedIndex = 4;
			dropdown.disabled = true;
			this.addAll(dropdown, ['cursor-not-allowed', 'disabled:opacity-75']);

			wrapper.classList.remove('hidden');
			input.disabled = false;
			return;
		}

		if (dropdown.value === 'Autre') {
			dropdown.disabled = true;
			this.addAll(dropdown, ['cursor-not-allowed', 'disabled:opacity-75']);

			wrapper.classList.remove('hidden');
			input.disabled = false;
		}
	}

	inputToDropdown() {
		const wrapper = this.weeklyHoursInputTarget;
		const dropdown = this.weeklyHoursDropdownTarget;
		const input = wrapper.querySelector('input[type="text"]');

		dropdown.selectedIndex = 0;
		dropdown.disabled = false;
		this.removeAll(dropdown, ['cursor-not-allowed', 'disabled:opacity-75']);

		wrapper.classList.add('hidden');
		input.disabled = true;
	}
}
