import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    lower: Number,
    mid: Number,
    higher: Number,
    warning: String,
  };

  connect() {
    setTimeout(() => {
      this._buildCounter(this.element.value.length);
      this.element.addEventListener('input', this._countCharacters.bind(this));
    }, 500);

    this.lowerValue = this.lowerValue || 150;
    this.midValue = this.midValue || 250;
    this.higherValue = this.higherValue || 500;
  }

  _countCharacters({ target: { value } }) {
    if (value.match(/<[^>]*>?/g)) {
      value = value.replace(/<[^>]*>?/g, '');
    }

    this.counter.innerText =
      value.length + `/${this.higherValue} characters ${this.warningValue}`;
    this._setColor(parseInt(this.counter.innerText));
  }

  _setColor(value) {
    if (value < this.lowerValue) {
      this.counter.style.color = '#22c55e';
    } else if (value < this.midValue) {
      this.counter.style.color = '#fbbf24';
    } else if (value > this.higherValue) {
      this.counter.style.color = '#ef4444';
    }
  }

  _buildCounter(defaultValue) {
    this.counter = document.createElement('span');
    this.counter.classList.add('text-xs');
    this.counter.innerHTML =
      defaultValue + `/${this.higherValue} characters ${this.warningValue}`;
    this.counter.id = 'counter';

    this.element.parentNode.insertBefore(
      this.counter,
      this.element.nextSibling
    );
  }
}
