import { useUtilities } from './mixins/utilities';
import { useRequests } from './mixins/requests';
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
	static targets = ['input', 'switch', 'form'];

	connect() {
		useUtilities(this);
		useRequests(this);
	}

	toggle(evt) {
		evt.preventDefault();

		this.toggleAll(evt.currentTarget, ['bg-indigo-600', 'bg-gray-200']);
		this.toggleAll(this.switchTarget, ['translate-x-5', 'translate-x-0']);
		this.inputTarget.checked = !this.inputTarget.checked;
		this._sendRequest();
	}

	async _sendRequest() {
		const data = await this.fetch(this.formTarget.action, {
			method: 'PATCH',
			body: new FormData(this.formTarget),
		});
	}
}
