import { Controller } from '@hotwired/stimulus';
import { useClickOutside } from 'stimulus-use';
import { useUtilities } from './mixins/utilities';

export default class extends Controller {
	static targets = ['dropdown', 'input'];

	connect() {
		useClickOutside(this, { element: this.dropdownTarget });
		useUtilities(this);
	}

	toggle(evt) {
		evt.preventDefault();
		evt.stopPropagation();

		this.toggleAll(this.dropdownTarget, ['hidden', 'block']);
	}

	change() {
		const inputs = this.element.querySelectorAll('input:checked');
		const values = Array.from(inputs).map((input) => input.value);

		this.inputTarget.value = values.join(', ');
	}

	clickOutside(evt) {
		this.addAll(this.dropdownTarget, ['hidden']);
	}
}
