import Swal from 'sweetalert2';
import { Controller } from '@hotwired/stimulus';
import { useRequests } from './mixins/requests';

export default class extends Controller {
	static values = {
		html: String,
		title: String,
		text: String,
		icon: String,
		confirmButtonText: String,
		replace: Object,
	};

	connect() {
		useRequests(this);
		this.csrf = document.querySelector('meta[name="csrf-token"]').content;
	}

	async alert(evt) {
		evt.preventDefault();
		evt.stopPropagation();

		this.swal = await Swal.fire({
			title: this.titleValue,
			text: this.textValue,
			icon: this.iconValue,
			showCancelButton: true,
			confirmButtonText: this.confirmButtonTextValue,
			cancelButtonText: 'Annuler',
			confirmButtonColor: '#ef4444',
		});

		if (this.swal.isConfirmed) {
			this._sendRequest();
			document.querySelector(this.replaceValue.element).outerHTML = '';
		}
	}

	async _sendRequest() {
		const options = {
			method: 'DELETE',
			headers: { 'X-CSRF-Token': this.csrf },
		};

		const data = await this.fetch(this.element.href, options);
		document.querySelector('#sortable-area').innerHTML = data.sortable_area;
		document.querySelector('#favorites-area').innerHTML = data.favorites_area;
	}
}
