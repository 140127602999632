import { Controller } from '@hotwired/stimulus'
import Swal from 'sweetalert2'

export default class extends Controller {
  static targets = ["form", "input", "preview", "loader", "previewText", "previewImg", "previewObject"]

  openDialog() {
    this.inputTarget.click()
  }

  async upload(event) {
    const file = event.target.files[0]
    if (!file) return

    this.displayFileName(file.name)
    this.previewFile(file)

    const confirmed = await this.confirmUpload(file.name)
    if (confirmed) {
      this.formTarget.submit()
      this.showLoader()
    } else {
      this.clearPreview()
    }
  }

  displayFileName(fileName) {
    this.previewTextTarget.innerText = fileName
    this.previewTextTarget.style.display = "block"
  }

  previewFile(file) {
    const reader = new FileReader()
    const fileType = file.type.split('/')[0]

    reader.onload = (e) => {
      if (fileType === 'image') {
        this.showImagePreview(e.target.result)
      } else if (fileType === 'application') {
        this.showObjectPreview(e.target.result)
      }
    }

    reader.readAsDataURL(file)
  }

  showImagePreview(src) {
    this.previewImgTarget.src = src
    this.previewImgTarget.style.display = "block"
  }

  showObjectPreview(data) {
    this.previewObjectTarget.data = data
    this.previewObjectTarget.style.display = "block"
    this.previewObjectTarget.style.aspectRatio = "21/30"
  }

  async confirmUpload(fileName) {
    const swal = await Swal.fire({
      title: "Confirmation",
      text: `Voulez-vous envoyer le fichier ${fileName} ?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Envoyer",
      cancelButtonText: 'Annuler',
      confirmButtonColor: '#2563EB',
    })

    return swal.isConfirmed
  }

  showLoader() {
    this.loaderTarget.classList.remove("hidden")
  }

  hideLoader() {
    this.loaderTarget.classList.add("hidden")
  }

  clearPreview() {
    this.hideLoader()
    this.previewTextTarget.innerText = ""
    this.previewTextTarget.style.display = "none"
    this.previewImgTarget.src = ""
    this.previewImgTarget.style.display = "none"
    this.previewObjectTarget.data = ""
    this.previewObjectTarget.style.display = "none"
    this.previewObjectTarget.style.aspectRatio = ""
    this.inputTarget.value = ""
  }
}
