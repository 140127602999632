import { Controller } from '@hotwired/stimulus';
import { useRequests } from './mixins/requests';

export default class extends Controller {
  static targets = ['participantsList', 'input'];
  static values = { attestationId: Number };

  connect() {
    useRequests(this);
    this.setModal();
  }

  async submit(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    const options = {
      method: 'POST',
      body: new FormData(evt.target),
    };

    const data = await this.fetch(evt.target.action, options);

    if (data.success) {
      window.location.href = data.redirect;
    } else {
      this.form.querySelector('#errors').classList.remove('hidden');
    }
  }

  async mission(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    const data = await this.fetch(
      `/participants?mission_id=${evt.params.missionId}`
    );
    this.participantsListTarget.innerHTML = data.html;
  }

  async search(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    const form = new FormData(evt.target);
    const params = new URLSearchParams(form).toString();

    const data = await this.fetch(`/participants?${params}`);
    this.participantsListTarget.innerHTML = data.html;
    this.inputTarget.focus();

    const length = this.inputTarget.value.length;
    this.inputTarget.setSelectionRange(length, length);
  }

  async attestation(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    this.setModal();
    this.modal.classList.remove('-z-1');
    this.modal.classList.remove('opacity-0');
    this.modal.classList.add('opacity-100');

    const data = await this.fetch(evt.currentTarget.href);
    this.modalBody.innerHTML = data.html;
    this.bindModal();
  }

  setModal() {
    this.modal = document.querySelector('#attestation-modal');

    if (this.modal) {
      this.modalBody = this.modal.querySelector('#attestation-modal-body');
    }
  }

  bindModal() {
    this.closeBtn = this.modal.querySelector('#attestation-close');
    this.form = this.modal.querySelector('#attestation-form');
    this.form.addEventListener('submit', this.submit.bind(this));

    this.closeBtn.addEventListener('click', (evt) => {
      evt.preventDefault();
      evt.stopPropagation();

      this.modal.classList.add('opacity-0');
      this.modal.classList.remove('opacity-100');
      this.modal.classList.add('-z-1');

      setTimeout(() => {
        this.modalBody.innerHTML = '<span class="loader"></span>';
      }, 500);
    });

    this.declinedInputs = this.modal.querySelector('#declined-inputs');
    this.acceptDeclineBtns = this.modal.querySelectorAll('.accept-decline-btn');

    this.acceptDeclineBtns.forEach((btn) => {
      btn.addEventListener('click', this.toggleDeclined.bind(this));
    });
  }

  toggleDeclined(evt) {
    if (evt.currentTarget.value == 1) {
      this.declinedInputs.classList.add('hidden');
      return;
    }

    this.declinedInputs.classList.remove('hidden');
  }
}
