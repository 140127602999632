import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
	connect() {
		setTimeout(() => this.close(), 5000);
	}

	close() {
		this.element.classList.remove('fixed');
		this.element.classList.add('hidden');
	}
}
