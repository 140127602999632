import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
	static values = {
		colorClass: String,
	};

	connect() {
		this.activeClass = Array.from(this.element.classList).find((className) =>
			className.includes('bg-')
		);

		this.activeClass = this.activeClass.replace(/-\d{3}/, '-500');
	}

	click(evt) {
		const input = this.element.closest('.css--checkbox').querySelector('input');

		setTimeout(() => {
			if (input.checked) {
				this.element.classList.add(this.activeClass);
			} else {
				this.element.classList.remove(this.activeClass);
			}
		}, 25);
	}
}
