import { Controller } from '@hotwired/stimulus';
import { useUtilities } from '../mixins/utilities';

export default class extends Controller {
	static targets = ['modal', 'backdrop', 'panel'];

	connect() {
		useUtilities(this);

		document
			.querySelector('#toggle-modal-btn')
			.addEventListener('click', this.toggle.bind(this));
	}

	toggle(evt) {
		evt.preventDefault();

		this.toggleAll(this.modalTarget, ['hidden', 'z-50']);
		this.toggleAll(this.backdropTarget, ['opacity-0', 'opacity-100']);
		this.toggleAll(this.panelTarget, [
			'opacity-100',
			'translate-y-0',
			'sm:scale-100',
		]);
	}
}
