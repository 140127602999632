import { Controller } from '@hotwired/stimulus';
import mapboxgl from '!mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

export default class extends Controller {
	static targets = ['map'];
	static values = { token: String, marker: Object };

	connect() {
		this._initMapbox();
	}

	_initMapbox() {
		mapboxgl.accessToken = this.tokenValue;
		this.map = new mapboxgl.Map({
			container: this.mapTarget,
			style: 'mapbox://styles/mapbox/streets-v10',
			center: this._coordinates(),
			zoom: 12,
		});

		this._setMarker();
		this.map.addControl(new mapboxgl.NavigationControl());
	}

	_setMarker() {
		new mapboxgl.Marker().setLngLat(this._coordinates()).addTo(this.map);
	}

	_coordinates() {
		return [this.markerValue.longitude, this.markerValue.latitude];
	}
}
