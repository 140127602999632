import { Controller } from '@hotwired/stimulus'
import { useRequests } from '../mixins/requests'

export default class extends Controller {
  static targets = ['form']

  connect() {
    useRequests(this)
  }

  async update(evt) {
    evt.preventDefault()
    evt.stopPropagation()

    const options = {
      method: this.formTarget.method.toUpperCase(),
      body: new FormData(this.formTarget),
    }
    const url = this.formTarget.action

    await this.handleRequest(url, options)
  }

  async deleteFile(evt) {
    evt.preventDefault()
    evt.stopPropagation()

    const confirmMessage = evt.currentTarget.dataset.confirm
    if (confirmMessage && !confirm(confirmMessage)) return

    const options = { method: 'PATCH' }
    const url = evt.target.href

    await this.handleRequest(url, options)
  }

  async handleRequest(url, options) {
    const data = await this.fetch(url, options)
    this.formTarget.outerHTML = data.html

    if (data.toast) {
      this.dispatchToastEvent(data.toast)
    }
  }

  dispatchToastEvent(toastData) {
    const event = new CustomEvent('toast', {
      detail: { value: toastData },
    })
    window.dispatchEvent(event)
  }
}
