import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
	static values = { content: String };

	connect() {
		tippy(this.element, {
			content: this.contentValue,
			arrow: true,
			placement: 'top',
			theme: 'light',
			allowHTML: true,
		});
	}
}
