import { Controller } from '@hotwired/stimulus';
import { useRequests } from '../mixins/requests';

export default class extends Controller {
  static targets = ['template', 'inputs', 'input'];
  static values = { count: Number };

  connect() {
    useRequests(this);
  }

  add(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    this.inputsTarget.insertAdjacentHTML('beforeend', this.generateHTML());
  }

  generateHTML() {
    const id = this.countValue + 1;
    this.countValue = id;

    const html = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, id);
    return html;
  }

  async update(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    const options = {
      method: 'PATCH',
      body: new FormData(this.element),
    };

    const data = await this.fetch(this.element.action, options);

    if (data.toast) {
      const event = new CustomEvent('toast', {
        detail: { value: data.toast },
      });

      window.dispatchEvent(event);
    }
  }

  toggle_for_destroy(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    const div = evt.target.closest(
      '[data-admin--settings-group-target="input"]'
    );
    div.querySelectorAll('svg').forEach((svg) => {
      svg.classList.toggle('hidden');
    });

    div.querySelectorAll('input, select').forEach((input) => {
      input.classList.toggle('opacity-25');
      input.disabled = !input.disabled;
    });
  }

  remove(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    evt.target.closest('.not-persisted').remove();
  }
}
