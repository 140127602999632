export const useUtilities = (controller) => {
	Object.assign(controller, {
		toggleAll(element, classes) {
			classes.forEach((className) => {
				element.classList.toggle(className);
			});
		},

		addAll(element, classes) {
			classes.forEach((className) => {
				element.classList.add(className);
			});
		},

		removeAll(element, classes) {
			classes.forEach((className) => {
				element.classList.remove(className);
			});
		},
	});
};
