import { Controller } from '@hotwired/stimulus';
import { useUtilities } from './mixins/utilities';

export default class extends Controller {
	static targets = ['highlightable'];
	static values = { classes: Array, selectedId: Number };

	connect() {
		useUtilities(this);

		document.addEventListener(
			'selection:close-over',
			this._clearAll.bind(this)
		);
	}

	toggle(evt) {
		const selectedId = parseInt(evt.currentTarget.dataset.highlightableId);

		console.log(selectedId);
		console.log(this.selectedIdValue);

		if (this.selectedIdValue === selectedId) {
			this.removeAll(evt.currentTarget, this.classesValue);
			this.selectedIdValue = null;
			return;
		}

		this.selectedIdValue = selectedId;
		this._clearAll();

		this.toggleAll(evt.currentTarget, this.classesValue);
	}

	_clearAll() {
		this.highlightableTargets.forEach((target) => {
			this.classesValue.forEach((className) => {
				target.classList.remove(className);
			});
		});
	}
}
