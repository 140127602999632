import { Controller } from '@hotwired/stimulus';
import { useRequests } from '../mixins/requests';

export default class extends Controller {
	connect() {
		useRequests(this);
	}

	async submit(evt) {
		evt.preventDefault();
		evt.stopPropagation();

		const options = {
			method: 'POST',
			body: new FormData(evt.target),
		};

		const data = await this.fetch(evt.target.action, options);

		data.forEach((item) => {
			this.element.querySelector(item.target).outerHTML = item.html;
		});
	}
}
