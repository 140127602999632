import { Controller } from '@hotwired/stimulus';
import { useRequests } from '../mixins/requests';

export default class extends Controller {
	static targets = [
		'main',
		'list',
		'recipients',
		'resource',
		'form',
		'textarea',
		'sending_date',
		'search_form',
		'messages_list',
		'trigger',
		'messagings_search',
		'messaging_items'
	];

	connect() {
		useRequests(this);
	}

	async create(evt) {
		evt.preventDefault();
		evt.stopPropagation();

		const options = {
			method: this.formTarget.method.toUpperCase(),
			body: new FormData(this.formTarget),
		};

		const preview = evt.params.preview;
		const url = this.formTarget.action + (preview ? '?preview=true' : '');
		const data = await this.fetch(url, options);
		this.formTarget.outerHTML = data.html;

		if (data.list) {
			this.listTarget.innerHTML = data.list;
		}

		if (data.toast) {
			const event = new CustomEvent('toast', {
				detail: { value: data.toast },
			});

			window.dispatchEvent(event);
		}
	}

	async form(evt) {
		evt.preventDefault();
		evt.stopPropagation();

		let url;

		if (evt.params.id == 'new') {
			url = '/admin/messages/new';
		} else {
			url = `/admin/messages/${evt.params.id}/edit`;
		}

		const data = await this.fetch(url);
		this.mainTarget.innerHTML = data.html;
	}

	async sending_list(evt) {
		evt.preventDefault();
		evt.stopPropagation();

		const data = await this.fetch(
			`/admin/messages_sending_list?resource=${evt.params.resource}`
		);

		this.resourceTarget.value = data.resource;
		this.recipientsTarget.value = data.identifiers;		

		if (data.resource == 'user' || data.resource == 'participation') {
			this.triggerTarget.querySelectorAll('option').forEach((option) => {
				if (option.value != '5') {
					option.disabled = true;
				}
				
				this.triggerTarget.value = '5';
				this.sending_dateTarget.classList.remove('hidden');
			});
		} else {
			this.triggerTarget.querySelectorAll('option').forEach((option) => {
				option.disabled = false;
			});
		}
	}

	async messagings_search(evt) {
		evt.preventDefault();
		evt.stopPropagation();

		const messageId = this.messagings_searchTarget.dataset.messageId

		const form = new FormData(this.messagings_searchTarget);
		const query = new URLSearchParams(form).toString();
		
		const data = await this.fetch(
			`/admin/messagings_list?${query}&message_id=${messageId}`
		);

		this.messaging_itemsTarget.innerHTML = data.html;
	}

	past(evt) {
		evt.preventDefault();
		evt.stopPropagation();

		const editor = this.textareaTarget.editor;
		const attribute = evt.params.attribute;
		editor.insertString(attribute);
	}

	trigger_select(evt) {
		evt.preventDefault();
		evt.stopPropagation();

		if (evt.target.value == '5') {
			this.sending_dateTarget.classList.remove('hidden');
			return;
		}

		this.sending_dateTarget.classList.add('hidden');
	}

	async search(evt) {
		evt.preventDefault();
		evt.stopPropagation();

		const fullUrl = `${this.search_formTarget.action}?query=${evt.target.value}`;
		const data = await this.fetch(fullUrl);
		this.messages_listTarget.innerHTML = data.html;
	}

	async resend(evt) {
		evt.preventDefault();
		evt.stopPropagation();

		const options = {
			method: 'PATCH',
			headers: { 
				'Content-Type': 'application/json', 
				'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').content 
			},
			body: JSON.stringify({ messaging_id: evt.params.messagingId }),
		}

		const response = await fetch('/admin/messages_resend', options);
		const data = await response.json();

		if (data.target) {
			const target = this.element.querySelector(data.target);
			target.outerHTML = data.html;
		}
		
		if (data.toast) {
			const event = new CustomEvent('toast', {
				detail: { value: data.toast },
			});

			window.dispatchEvent(event);
		}
	}
}
