import { Controller } from '@hotwired/stimulus';
import { useRequests } from '../mixins/requests';

export default class extends Controller {
	connect() {
		useRequests(this);
    console.log('update user status controller connected')
	}

	async submit(evt) {
    console.log(evt)
		evt.preventDefault();
		evt.stopPropagation();

		const options = {
			method: 'PATCH',
			body: new FormData(evt.target),
		};

		const data = await this.fetch(evt.target.action, options);
    
		if (data.toast) {
      const event = new CustomEvent('toast', {
        detail: { value: data.toast },
      });

      window.dispatchEvent(event);
    }
	}
}
