import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
	static targets = ['hours', 'minutes', 'seconds'];
	static values = { time: String };

	connect() {
		this.time = new Date(this.timeValue).getTime();
		this.start();
	}

	start() {
		this.interval = setInterval(() => {
			const now = new Date().getTime();
			const distance = this.time - now;

			const hours = Math.floor(
				(distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
			);
			const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
			const seconds = Math.floor((distance % (1000 * 60)) / 1000);

			this.hoursTarget.textContent = hours;
			this.minutesTarget.textContent = minutes;
			this.secondsTarget.textContent = seconds;

			if (distance <= 0) {
				clearInterval(this.interval);
				this.element.innerText = 'délai dépassé';
			}
		}, 1000);
	}
}
