import { Controller } from '@hotwired/stimulus';
import Cleave from 'cleave.js';
require('cleave.js/dist/addons/cleave-phone.fr');

export default class extends Controller {
	static values = { type: String };

	connect() {
		if (this.typeValue === 'phone') this._phone();
		if (this.typeValue === 'number') this._number();
	}

	_phone() {
		const options = {
			prefix: '+33',
			phone: true,
			phoneRegionCode: 'fr',
			noImmediatePrefix: true,
		};

		this.cleave = new Cleave(this.element, options);
	}

	_number() {
		const options = {
			numeral: true,
			timePattern: ['h'],
		};

		this.cleave = new Cleave(this.element, options);
	}
}
