import { Controller } from '@hotwired/stimulus';
import { useRequests } from '../mixins/requests';

export default class extends Controller {
	static targets = ['focus', 'section', 'back'];
	static values = { section: String, currentUrl: String, previousUrl: String };

	connect() {
		useRequests(this);
	}

	async section(evt) {
		evt.preventDefault();
		evt.stopPropagation();

		this.focusTarget.innerHTML = '';
		this.sectionTarget.classList.remove('hidden');
	}

	async focus(evt) {
		evt.preventDefault();
		evt.stopPropagation();

		let url = null;
		if (evt.params.url) {
			url = evt.params.url;
		} else {
			url = evt.currentTarget.href;
		}

		const data = await this.fetch(url);
		this.focusTarget.innerHTML = data.html;
		this.sectionTarget.classList.add('hidden');

		this.previousUrlValue = this.currentUrlValue;
		this.currentUrlValue = url;
	}

	async previous(evt) {
		evt.preventDefault();
		evt.stopPropagation();

		const data = await this.fetch(this.previousUrlValue);
		this.focusTarget.innerHTML = data.html;
		this.sectionTarget.classList.add('hidden');

		this.currentUrlValue = this.previousUrlValue;
	}
}
