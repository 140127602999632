import { Controller } from '@hotwired/stimulus';
import { useRequests } from './mixins/requests';
import { useUtilities } from './mixins/utilities';

export default class extends Controller {
	static targets = ['slideOver', 'slideOverContent'];
	static values = { missionId: Number, open: Boolean };

	connect() {
		useRequests(this);
		useUtilities(this);
	}

	details(evt) {
		evt.preventDefault();

		const missionId = parseInt(evt.currentTarget.dataset.missionId);
		this.url = `/missions/${missionId}/details?page=selection`;

		if (isNaN(missionId)) {
			return;
		}

		if (isNaN(this.missionIdValue)) {
			this.missionIdValue = missionId;
			this.openValue = true;
			this.slideOverTarget.classList.add('open');
			this._sendRequest();
			return;
		}

		if (!this.openValue && this.missionIdValue !== missionId) {
			this.missionIdValue = missionId;
			this.openValue = true;
			this.slideOverTarget.classList.add('open');
			this._sendRequest();
			return;
		}

		if (this.missionIdValue !== missionId) {
			this.missionIdValue = missionId;
			if (this.openValue) this.slideOverTarget.classList.remove('open');

			setTimeout(() => {
				this.slideOverTarget.classList.add('open');
				this._sendRequest();
			}, 200);

			return;
		}

		if (!this.openValue) {
			this.openValue = true;
			this.slideOverTarget.classList.add('open');
			return;
		}

		if (this.missionIdValue === missionId) {
			this.openValue = false;
			this.slideOverTarget.classList.remove('open');
		}
	}

	close(evt) {
		evt.preventDefault();
		evt.stopPropagation();

		this.openValue = false;
		this.slideOverTarget.classList.remove('open');

		const event = new CustomEvent('selection:close-over');
		document.dispatchEvent(event);
	}

	async _sendRequest() {
		const data = await this.fetch(this.url);
		console.log(data);
		this.slideOverContentTarget.innerHTML = data.html;
	}
}
