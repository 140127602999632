import { Controller } from '@hotwired/stimulus';
import { useRequests } from './mixins/requests';

export default class extends Controller {
	static targets = ['list', 'pagination'];
	static values = {};

	initialize() {
		let options = {
			rootMargin: '200px',
		};

		this.intersectionObserver = new IntersectionObserver(
			(entries) => this.processIntersectionEntries(entries),
			options
		);
	}

	connect() {
		useRequests(this);
		this.intersectionObserver.observe(this.paginationTarget);
	}

	disconnect() {
		this.intersectionObserver.unobserve(this.paginationTarget);
	}

	processIntersectionEntries(entries) {
		entries.forEach((entry) => {
			// prettier-ignore
			if (entry.isIntersecting) { this._loadMore(); }
		});
	}

	async _loadMore() {
		const next_page = this.paginationTarget.querySelector("a[rel='next']");

		// prettier-ignore
		if (next_page == null) { return; }

		const url = next_page.href;
		const data = await this.fetch(url);

		this.listTarget.insertAdjacentHTML('beforeend', data.html);
		this.paginationTarget.innerHTML = data.pagination;
	}
}
