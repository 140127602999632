import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['start_time', 'end_time', 'hours'];

  compute_hours(evt) {
    if (this.start_timeTarget.value == '' || this.end_timeTarget.value == '') {
      return;
    }

    const start = this.start_timeTarget.value;
    const end = this.end_timeTarget.value;

    const start_date = new Date('1970-01-01T' + start + ':00');
    const end_date = new Date('1970-01-01T' + end + ':00');

    if (end_date < start_date) {
      this.hoursTarget.value = 0;
      return;
    }

    const diff = end_date.getTime() - start_date.getTime();
    const totalMinutes = diff / (1000 * 60);
    const total = totalMinutes / 60;

    this.hoursTarget.value = total.toFixed(2);
  }
}
