import flatpickr from 'flatpickr';
import { French } from 'flatpickr/dist/l10n/fr.js';
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
	static values = { time: Boolean, noCalendar: Boolean };

	connect() {
		flatpickr(this.element, {
			locale: French,
			enableTime: this.timeValue,
			noCalendar: this.noCalendarValue || false,
		});
	}
}
