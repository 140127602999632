import { Controller } from '@hotwired/stimulus';
import { useRequests } from '../mixins/requests';

export default class extends Controller {
	connect() {
		useRequests(this);
	}

	async submit(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    const options = {
      method: 'PATCH',
      body: new FormData(this.element),
    }

    const data = await this.fetch(this.element.action, options);

    if (data.toast) {
      const event = new CustomEvent('toast', {
        detail: { value: data.toast },
      });

      window.dispatchEvent(event);
    }
	}
}
