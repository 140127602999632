import { Controller } from '@hotwired/stimulus';
import { useRequests } from './mixins/requests';

export default class extends Controller {
	static targets = ['mobileMenu'];

	toggleMobileMenu(evt) {
		evt.preventDefault();
		this.mobileMenuTarget.classList.toggle('hidden');
	}
}
