import { Controller } from '@hotwired/stimulus';
import { useRequests } from '../mixins/requests';

export default class extends Controller {
	static targets = ['form', 'input'];
	static values = { list: String };

	connect() {
		useRequests(this);

		this.element.querySelectorAll('a').forEach((link) => {
			link.setAttribute('target', '_blank');
		})
	}

	async submit(evt) {
		evt.preventDefault();
		evt.stopPropagation();

		const options = {
			method: 'POST',
			body: new FormData(this.formTarget),
		};

		const data = await this.fetch(this.formTarget.action, options);

		if (data.success) {
			this.element.querySelector(`#${this.listValue}`).outerHTML = data.html;
			this.inputTarget.value = '';
		}
	}

	async destroy(evt) {
		evt.preventDefault();
		evt.stopPropagation();

		const options = {
			method: 'DELETE',
		};

		const data = await this.fetch(`/admin/comments/${evt.params.id}`, options);

		if (data.success) {
			this.element.querySelector(`#comment_${evt.params.id}`).remove();
		}
	}
}
